import React from 'react';
import NavBar from './../NavBar';
import './experience.css';

const Experience= () => {
  return(
    <div>
      <NavBar />
      <div className="experience_container">

      </div>
    </div>
  )
}

export default Experience;
